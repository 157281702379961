import './Machines.css';

function Machines() {
    return (
        <div className='machines-container'>
            <div className='title'>
                <h1>Machines</h1>
            </div>
            <div className='individual-machines'>

            </div>
        </div>
    )
};

export default Machines;
